/* Основные стили */
body {
  background-color: #1a1a2e;
  color: #ffffff;
  font-family: "Fira Code", monospace;
  margin: 0;
  padding: 0;
}

/* Заголовки */
h1, h2 {
  color: #76c7c0;
}

/* Контейнеры */
.task-list-container, .task-form {
  margin: 20px auto;
  padding: 20px;
  max-width: 600px;
  background-color: #2b2d42;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Список задач */
.task-list {
  list-style-type: none;
  padding: 0;
}

.task-item {
  margin-bottom: 15px;
  padding: 10px;
  background-color: #1a1a2e;
  border: 1px solid #76c7c0;
  border-radius: 5px;
}

.task-title {
  color: #ff79c6;
}

.task-date {
  color: #c792ea;
}

.task-description {
  font-style: italic;
}

.task-status {
  font-weight: bold;
}

.task-status.completed {
  color: #a8df65;
}

.task-status.pending {
  color: #ff6f61;
}

/* Поля ввода */
.input-field, .textarea-field {
  width: 96%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #76c7c0;
  border-radius: 5px;
  background-color: #1a1a2e;
  color: #ffffff;
}

.textarea-field {
  resize: none;
  height: 100px;
}

/* Кнопки */
.submit-button {
  width: 100%;
  padding: 10px;
  background-color: #76c7c0;
  border: none;
  border-radius: 5px;
  color: #1a1a2e;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s ease;
}

.submit-button:hover {
  background-color: #c792ea;
}

.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Arial, sans-serif;
}